import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ValidationError } from '../../components';

import css from './FieldSelect.module.css';

const FieldSelectComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    iconInfoTextId,
    intl,
    ...rest
  } = props;

  const [showIconInfo, setShowIconInfo] = useState(false);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames({
    [selectClassName]: selectClassName,
    [css.selectError]: hasError,
  });
  const handleChange = e => {
    input.onChange(e);
    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  const selectProps = { className: selectClasses, id, ...input, onChange: handleChange, ...rest };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.iconLabelRow}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        {iconInfoTextId && (
          <div
            className={css.infoIcon}
            onMouseEnter={() => setShowIconInfo(true)}
            onMouseLeave={() => setShowIconInfo(false)}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            {showIconInfo && (
              <div className={css.iconInfoBox}>
                <ul>
                  {iconInfoTextId.map((textId, index) => (
                    <li key={index}>
                      <strong>{intl.formatMessage({ id: textId.label })}</strong>
                      {intl.formatMessage({ id: textId.details })}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
      <select {...selectProps}>{children}</select>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
  iconInfoTextId: null,
};

const { string, object, node } = PropTypes;

FieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
  iconInfoTextId: string,
  intl: object.isRequired,
};

const FieldSelect = props => {
  return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelect;
