import React from 'react';

const icons = {
  expiredClock: (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="#ED1B24" strokeWidth="1.5" />
      <path
        d="M11.992 15H12.001"
        stroke="#ED1B24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12L12 8"
        stroke="#ED1B24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  clock: (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="#f77f00" strokeWidth="1.5" />
      <path
        d="M12 8V12L14 14"
        stroke="#f77f00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  tabRibbon: (
    <svg
      width="800"
      height="780"
      viewBox="0 0 800 780"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.24"
        d="M310.539 676.953C301.636 670.931 290.795 667.378 279.269 667.392C271.58 667.392 264.177 668.954 257.483 671.798C247.43 676.05 238.928 683.117 232.894 692.045C226.86 700.948 223.319 711.789 223.344 723.314C223.331 730.989 224.895 738.393 227.738 745.086C232 755.153 239.056 763.654 247.972 769.675C256.888 775.723 267.741 779.25 279.267 779.25C286.942 779.25 294.335 777.686 301.027 774.843C311.092 770.592 319.594 763.511 325.63 754.597C331.652 745.693 335.192 734.839 335.192 723.312C335.192 715.639 333.616 708.247 330.786 701.54C326.524 691.476 319.455 682.975 310.539 676.953ZM300.047 732.089C298.341 736.12 295.46 739.597 291.855 742.025C288.236 744.454 283.999 745.862 279.269 745.875C276.103 745.862 273.171 745.242 270.496 744.09C266.477 742.412 262.988 739.517 260.558 735.925C258.128 732.293 256.72 728.043 256.708 723.312C256.722 720.159 257.353 717.239 258.478 714.539C260.17 710.52 263.066 707.045 266.669 704.604C270.288 702.175 274.539 700.779 279.269 700.765C282.422 700.765 285.342 701.4 288.03 702.536C292.061 704.228 295.524 707.109 297.953 710.728C300.396 714.345 301.803 718.584 301.803 723.312C301.803 726.481 301.172 729.401 300.047 732.089Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M338.321 433.076C349.692 429.768 356.23 417.842 352.896 406.457L297.308 216.151C294 204.767 282.086 198.242 270.702 201.562C259.319 204.884 252.806 216.797 256.114 228.168L311.702 418.5C315.035 429.872 326.949 436.409 338.321 433.076Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M401.531 404.339C404.866 415.723 416.78 422.248 428.149 418.926C439.521 415.606 446.06 403.693 442.725 392.309L391.169 215.79C387.861 204.407 375.947 197.881 364.564 201.19C353.18 204.523 346.655 216.437 349.975 227.807L401.531 404.339Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M491.491 390.603C494.811 401.987 506.738 408.512 518.108 405.192C529.48 401.87 536.017 389.943 532.683 378.573L485.042 215.429C481.721 204.045 469.808 197.52 458.436 200.854C447.053 204.162 440.527 216.089 443.849 227.459L491.491 390.603Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M581.164 375.951C584.499 387.336 596.411 393.861 607.781 390.539C619.153 387.206 625.691 375.306 622.37 363.922L578.889 215.054C575.581 203.672 563.655 197.159 552.271 200.467C540.9 203.801 534.375 215.714 537.696 227.084L581.164 375.951Z"
        fill="black"
      />
      <path
        opacity="0.24"
        d="M263.635 603.445C257.586 603.445 251.928 602.229 246.733 600.045C238.967 596.764 232.286 591.233 227.61 584.281C223.113 577.6 220.45 569.731 220.244 561.1C220.475 550.8 223.758 541.861 229.521 534.714C232.505 531.031 236.188 527.801 240.658 525.126C245.077 522.49 250.285 520.397 256.397 519.079L638.17 456.94C663.11 452.87 682.956 433.8 687.995 409.028L734.694 179.453V179.428C735.236 176.765 735.496 174.053 735.496 171.365C735.496 162.087 732.316 153.017 726.347 145.703C718.644 136.297 707.119 130.831 694.96 130.831H166.335L148.325 68.9528V68.9653C140.055 41.3012 117.366 20.3434 89.1455 14.2965L24.5127 0.455903C13.5799 -1.89566 2.8049 5.08246 0.451773 16.0262C-1.88573 26.97 5.07677 37.7465 16.0361 40.0856L80.6424 53.9372C94.3893 56.8825 105.452 67.0903 109.483 80.5809L227.583 486.259C224.922 487.5 222.35 488.843 219.869 490.329C207.141 497.915 196.933 508.562 190.086 520.992C183.533 532.801 180.058 546.189 179.724 560.001H179.672V562.107H179.724C179.981 572.898 182.281 583.247 186.274 592.693C192.671 607.798 203.291 620.578 216.677 629.622C230.052 638.678 246.319 643.99 263.633 643.976H478.121C478.055 641.975 477.817 640.023 477.817 638.007C477.817 626.184 479.038 614.645 481.214 603.443H263.635V603.445ZM178.122 171.367H694.963H694.977L648.278 400.953C646.597 409.223 639.981 415.581 631.661 416.925L266.891 476.311L178.122 171.367Z"
        fill="black"
      />
      <path
        d="M658.756 496.765C580.75 496.765 517.513 560.003 517.513 638.009C517.513 716.015 580.75 779.253 658.756 779.253C736.775 779.253 800 716.015 800 638.009C800 560.003 736.775 496.765 658.756 496.765Z"
        fill="#dd1d1d"
      />
      <path
        d="M694.484 638.539L734.099 597.31L700.147 564.687L660.532 605.917L619.302 566.301L586.687 600.245L627.918 639.861L588.302 681.091L622.254 713.713L661.869 672.483L703.09 712.089L735.704 678.145L694.484 638.539Z"
        fill="white"
      />
    </svg>
  ),
  offersIcon: (
    <svg
      width="256px"
      height="256px"
      viewBox="-2.5 -2.5 30.00 30.00"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0">
        <path
          transform="translate(-2.5, -2.5), scale(1.875)"
          fill="#DD1D1D"
          d="M9.166.33a2.25 2.25 0 00-2.332 0l-5.25 3.182A2.25 2.25 0 00.5 5.436v5.128a2.25 2.25 0 001.084 1.924l5.25 3.182a2.25 2.25 0 002.332 0l5.25-3.182a2.25 2.25 0 001.084-1.924V5.436a2.25 2.25 0 00-1.084-1.924L9.166.33z"
          strokeWidth="0"
        ></path>
      </g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.817 16.063V14.721C17.817 14.3887 17.949 14.07 18.184 13.835L19.133 12.886C19.6223 12.3967 19.6223 11.6033 19.133 11.114L18.184 10.165C17.949 9.93001 17.817 9.61131 17.817 9.27899V7.93599C17.817 7.24398 17.256 6.68299 16.564 6.68299H15.221C14.8887 6.68299 14.57 6.55097 14.335 6.31599L13.386 5.36699C12.8967 4.87767 12.1033 4.87767 11.614 5.36699L10.665 6.31599C10.43 6.55097 10.1113 6.68299 9.77899 6.68299H8.43599C8.1035 6.68299 7.78464 6.81514 7.54963 7.05034C7.31462 7.28554 7.18273 7.6045 7.18299 7.93699V9.27899C7.18299 9.61131 7.05097 9.93001 6.81599 10.165L5.86699 11.114C5.37767 11.6033 5.37767 12.3967 5.86699 12.886L6.81599 13.835C7.05097 14.07 7.18299 14.3887 7.18299 14.721V16.063C7.18299 16.755 7.74398 17.316 8.43599 17.316H9.77899C10.1113 17.316 10.43 17.448 10.665 17.683L11.614 18.632C12.1033 19.1213 12.8967 19.1213 13.386 18.632L14.335 17.683C14.57 17.448 14.8887 17.316 15.221 17.316H16.563C16.8955 17.3163 17.2144 17.1844 17.4496 16.9493C17.6848 16.7143 17.817 16.3955 17.817 16.063Z"
          stroke="#ffffff"
          strokeWidth="0.7249999999999999"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        ></path>{' '}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.78202 10.641C9.50715 10.3662 9.42492 9.95286 9.57366 9.59375C9.7224 9.23464 10.0728 9.00049 10.4615 9.00049C10.8502 9.00049 11.2006 9.23464 11.3494 9.59375C11.4981 9.95286 11.4159 10.3662 11.141 10.641C10.7657 11.0163 10.1573 11.0163 9.78202 10.641Z"
          stroke="#ffffff"
          strokeWidth="0.7249999999999999"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        ></path>{' '}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.859 14.718C13.5841 14.4431 13.5019 14.0298 13.6506 13.6707C13.7994 13.3115 14.1498 13.0774 14.5385 13.0774C14.9272 13.0774 15.2776 13.3115 15.4263 13.6707C15.5751 14.0298 15.4928 14.4431 15.218 14.718C14.8427 15.0932 14.2343 15.0932 13.859 14.718Z"
          stroke="#ffffff"
          strokeWidth="0.7249999999999999"
          strokeLinecap="round"
          fill="none"
        ></path>{' '}
        <path
          d="M15.218 9.28101L9.78101 14.719"
          stroke="#ffffff"
          strokeWidth="0.7249999999999999"
          strokeLinecap="round"
          fill="none"
        ></path>{' '}
      </g>
    </svg>
  ),
};

const Icons = ({ name }) => {
  return icons[name];
};

export default Icons;
