import React, { useEffect, useRef, useState, useMemo } from 'react';
import css from './LocationAutocomplete.module.css';
import { countryCodeMap } from '../../util/configOptions';

// To use the get place details api
// The google lib (specifically the map) needs a div to attach itself to
// So an empty (hidden) div was added
const ghostDivForGoogleMapsSearch = 'ghost-div-for-gmap-search';

export const LocationAutocomplete = props => {
  const {
    handleLocationUpdate,
    country,
    placeholder,
    className,
    countryCode,
    includeStreetNumber,
  } = props;

  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(props.input.value);

  const getComponentRestrictions = () => {
    if (countryCode) {
      // If a country code is provided, return it
      return { country: countryCode };
    } else if (country) {
      // If a country is provided, find the country code
      return { country: countryCodeMap[country] };
    }
    // If there are no restrictions, return all countries
    return { country: Object.values(countryCodeMap) };
  };
  const autocompleteRef = useRef(null);
  const componentRestrictions = useMemo(() => getComponentRestrictions(), [countryCode, country]); // Memoize this value

  const typeRestrictions = ['address'];

  const handleAutocompleteDefocusEvent = () => {
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      {
        input: inputRef.current.value,
        componentRestrictions,
        types: typeRestrictions,
      },
      (prediction, predictionStatus) => {
        if (predictionStatus === google.maps.places.PlacesServiceStatus.OK) {
          const predictionId = prediction[0].place_id;
          const map = new window.google.maps.Map(
            document.getElementById(ghostDivForGoogleMapsSearch)
          );

          // Initialize the Places Service
          const placesService = new window.google.maps.places.PlacesService(map);
          placesService.getDetails({ placeId: predictionId }, (place, placeStatus) => {
            if (placeStatus === google.maps.places.PlacesServiceStatus.OK) {
              handlePlaceChange(place);
            }
          });
        }
      }
    );
  };

  const handlePlaceChange = place => {
    const street = place.address_components.find(address => address.types.includes('route'))
      ?.long_name;
    const city = place.address_components.find(address => address.types.includes('locality'))
      ?.long_name;
    const zipCode = place.address_components.find(address => address.types.includes('postal_code'))
      ?.long_name;
    const streetNumber = place.address_components.find(address =>
      address.types.includes('street_number')
    )?.long_name;

    handleLocationUpdate({
      street: street || '',
      streetNumber: streetNumber || '',
      city,
      zipCode,
    });

    if (includeStreetNumber) {
      setInputValue(`${street}${streetNumber ? ' ' + streetNumber : ''}`);
    } else {
      setInputValue(street || '');
    }
  };

  // Hook to monitor changes in the props input value
  useEffect(() => {
    setInputValue(props.input.value);
  }, [props.input.value]);

  // Hook to mount the autocomplete component and
  useEffect(() => {
    if (!inputRef.current) return;
    const options = {
      types: typeRestrictions,
      componentRestrictions,
    };
    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);

    // Listener to handle autocomplete results
    autocompleteRef.current.addListener('place_changed', () => {
      const place = autocompleteRef.current.getPlace();
      handlePlaceChange(place);
    });
    return () => {
      autocompleteRef.current.unbindAll();
      google.maps.event.clearInstanceListeners(autocompleteRef.current);
    };
  }, [country, props.input.value, componentRestrictions]);

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const containerClassName = className ? className : css.locationAutocompleteContainer;

  return (
    <div className={containerClassName}>
      <div id={ghostDivForGoogleMapsSearch} className={css.ghostDiv}></div>
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleAutocompleteDefocusEvent}
      />
    </div>
  );
};
