import { defaultLocale } from '../intl';

/**
 * Replaces dynamic values in translation keys.
 *
 * This function takes a translations object and a dynamic value (typically a brand name),
 * and creates new translation keys by replacing 'purchase-cash' with 'purchase-cash-{dynamicValue}'.
 * It's used to create brand-specific translation keys.
 *
 * @param {Object} translations - The original translations object
 * @param {string} dynamicValue - The value to insert into the new keys (typically a brand name)
 * @returns {Object} A new translations object with updated keys
 */
export const replaceDynamicValues = (translations, dynamicValue) => {
  const dynamicKeyPart = 'purchase-cash';
  const dynamicValuePart = dynamicValue.replace(/_/g, '-');

  return Object.keys(translations).reduce((acc, key) => {
    const newKey = key.replace(dynamicKeyPart, `purchase-cash-${dynamicValuePart}`);
    acc[newKey] = translations[key];
    return acc;
  }, {});
};

export const multilanguageListingTitle = (currentListing, currentLocale) => {
  const { title = '', publicData = {} } = currentListing?.attributes || {};

  const titleData = {
    [defaultLocale]: publicData.title, // Map defaultLocale to publicData.title
    // Map other languages, skipping defaultLocale if already mapped
    ...(defaultLocale !== 'en' && { en: publicData.title_en }),
    ...(defaultLocale !== 'fr' && { fr: publicData.title_fr }),
    ...(defaultLocale !== 'de' && { de: publicData.title_de }),
    ...(defaultLocale !== 'it' && { it: publicData.title_it }),
    ...(defaultLocale !== 'es' && { es: publicData.title_es }),
  };

  const enhanceTitle = (title, titleTranslated) => {
    const finalTitle = titleTranslated ? titleTranslated : title;
    return finalTitle;
  };

  return enhanceTitle(title, titleData?.[currentLocale]);
};

export const multilanguageListingDescription = (currentListing, currentLocale) => {
  const { description = '', publicData = {} } = currentListing.attributes;

  const descriptionData = {
    [defaultLocale]: publicData.descriptionHTML, // Map defaultLocale to publicData.descriptionHTML
    // Map other languages, skipping defaultLocale if already mapped
    ...(defaultLocale !== 'en' && { en: publicData.descriptionHTML_en }),
    ...(defaultLocale !== 'fr' && { fr: publicData.descriptionHTML_fr }),
    ...(defaultLocale !== 'de' && { de: publicData.descriptionHTML_de }),
    ...(defaultLocale !== 'it' && { it: publicData.descriptionHTML_it }),
    ...(defaultLocale !== 'es' && { es: publicData.descriptionHTML_es }),
  };

  const enhanceDescription = (plainDescription, htmlDescription) => {
    return htmlDescription || plainDescription;
  };

  return enhanceDescription(description, descriptionData[currentLocale]);
};

/**
 * Retrieves the multilanguage item name based on the current locale and default locale.
 * @param {Object} item - The item object containing name data.
 * @param {string} currentLocale - The current locale.
 * @param {string} [defaultLocale='en'] - The default locale.
 * @returns {string} The item name in the appropriate language.
 */
export const multilanguageItemName = (item, currentLocale, defaultLocale = 'en') => {
  const nameData = {
    [defaultLocale]: item.gt_item_name,
    ...(defaultLocale !== 'en' && { en: item.gt_item_name }),
    ...(defaultLocale !== 'fr' && { fr: item.gt_item_name_fr }),
    ...(defaultLocale !== 'de' && { de: item.gt_item_name_de }),
    ...(defaultLocale !== 'it' && { it: item.gt_item_name_it }),
    ...(defaultLocale !== 'es' && { es: item.gt_item_name_es }),
  };

  return nameData[currentLocale] || nameData[defaultLocale];
};

/**
 * Retrieves the multilanguage item season based on the current locale and default locale.
 * @param {Object} item - The item object containing season data.
 * @param {string} currentLocale - The current locale.
 * @param {string} [defaultLocale='en'] - The default locale.
 * @returns {string} The item season in the appropriate language.
 */
export const multilanguageItemSeason = (item, currentLocale, defaultLocale = 'en') => {
  const seasonData = {
    [defaultLocale]: item.item_season,
    ...(defaultLocale !== 'en' && { en: item.item_season }),
    ...(defaultLocale !== 'fr' && { fr: item.item_season_fr }),
    ...(defaultLocale !== 'de' && { de: item.item_season_de }),
    ...(defaultLocale !== 'it' && { it: item.item_season_it }),
    ...(defaultLocale !== 'es' && { es: item.item_season_es }),
  };

  return seasonData[currentLocale] || seasonData[defaultLocale];
};

/**
 * Retrieves the multilanguage item description based on the current locale and default locale.
 * @param {Object} item - The item object containing description data.
 * @param {string} currentLocale - The current locale.
 * @param {string} [defaultLocale='en'] - The default locale.
 * @returns {string} The item description in the appropriate language.
 */
export const multilanguageItemDescription = (item, currentLocale, defaultLocale = 'en') => {
  const descriptionData = {
    [defaultLocale]: item.gt_item_description,
    ...(defaultLocale !== 'en' && { en: item.gt_item_description }),
    ...(defaultLocale !== 'fr' && { fr: item.gt_item_description_fr }),
    ...(defaultLocale !== 'de' && { de: item.gt_item_description_de }),
    ...(defaultLocale !== 'it' && { it: item.gt_item_description_it }),
    ...(defaultLocale !== 'es' && { es: item.gt_item_description_es }),
  };

  return descriptionData[currentLocale] || descriptionData[defaultLocale];
};
