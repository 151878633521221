import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form, PrimaryButton, FieldSelect, NamedLink } from '../../components';
import { languagesHelper as language } from '../../helpers/languages';

import css from './SettingsPopup.module.css';
import classNames from 'classnames';

const SettingsPopupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        intl,
        values,
        localeOptions,
        currencyOptions,
        onCloseModal,
        languageAndCurrencyEnabled,
        languageSwitchEnabled,
        currencySwitchEnabled,
      } = fieldRenderProps;

      return (
        <Form className={css.form} onSubmit={handleSubmit}>
          <h2 className={css.formTitle}>{intl.formatMessage({ id: 'SettingsPopupForm.title' })}</h2>

          <div className={css.fieldsWrapper}>
            {languageSwitchEnabled && (
              <FieldSelect
                id="language"
                name="language"
                className={css.field}
                label={intl.formatMessage({ id: 'SettingsPopupForm.languageLabel' })}
              >
                {localeOptions.map(l => {
                  return (
                    <option key={l.key} value={l.key}>
                      {language.labelsTranslator(l.key, intl)}
                    </option>
                  );
                })}
              </FieldSelect>
            )}

            {languageSwitchEnabled && currencySwitchEnabled && (
              <div className={css.fieldSeparator} />
            )}

            {currencySwitchEnabled && (
              <FieldSelect
                id="currency"
                name="currency"
                className={css.field}
                label={intl.formatMessage({ id: 'SettingsPopupForm.currencyLabel' })}
              >
                {currencyOptions.map(c => {
                  return (
                    <option key={c.key} value={c.key}>
                      {c.labelPopup}
                    </option>
                  );
                })}
              </FieldSelect>
            )}
          </div>

          <div className={css.buttonsWrapper}>
            <PrimaryButton
              type="button"
              onClick={() => onCloseModal()}
              className={classNames(css.button, css.buttonCancel)}
            >
              <FormattedMessage id="SettingsPopupForm.cancel" />
            </PrimaryButton>

            <PrimaryButton type="submit" className={classNames(css.button, css.buttonSave)}>
              <FormattedMessage id="SettingsPopupForm.saveChanges" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SettingsPopupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
};

const { string } = PropTypes;

SettingsPopupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  intl: intlShape.isRequired,
};

const SettingsPopupForm = compose(injectIntl)(SettingsPopupFormComponent);
SettingsPopupForm.displayName = 'SettingsPopupForm';

export default SettingsPopupForm;
