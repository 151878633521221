/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

import { countryCodes } from "../translations/countryCodes";
import { getBrandProcessCash, getDefaultProcessAlias } from '../transactions/transactionHelpers';

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
    {
        "key": "category_gender",
        "scope": "public",
        "schemaType": "multi-enum",
        "enumOptions": [
            {
                "label": "boys",
                "option": "boys"
            },
            {
                "label": "girls",
                "option": "girls"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Category"
        },
        "showConfig": {
            "label": "Category"
        },
        "saveConfig": {
            "label": "Category"
        }
    },
    {
        "key": "type",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {
                "label": "Top",
                "option": "top"
            },
            {
                "label": "Bottom",
                "option": "bottom"
            },
            {
                "label": "Accessory",
                "option": "accessory"
            },
            {
                "label": "Dress",
                "option": "dress"
            },
            {
                "label": "Overall",
                "option": "overall"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Type"
        },
        "showConfig": {
            "label": "Type"
        },
        "saveConfig": {
            "label": "Type"
        }
    },
    {
        "key": "size",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {
                "label": "68 / 6 months",
                "option": "68"
            },
            {
                "label": "74 / 9 months",
                "option": "74"
            },
            {
                "label": "80 / 1 year",
                "option": "80"
            },
            {
                "label": "86 / 18 months",
                "option": "86"
            },
            {
                "label": "92 / 2 years",
                "option": "92"
            },
            {
                "label": "98 / 3 years",
                "option": "98"
            },
            {
                "label": "104 / 4 years",
                "option": "104"
            },
            {
                "label": "110 / 5 years",
                "option": "110"
            },
            {
                "label": "116 / 6 years",
                "option": "116"
            },
            {
                "label": "122 / 7 years",
                "option": "122"
            },
            {
                "label": "128 / 8 years",
                "option": "128"
            },
            {
                "label": "134 / 9 years",
                "option": "134"
            },
            {
                "label": "140 / 10 years",
                "option": "140"
            },
            {
                "label": "152 / 12 years",
                "option": "152"
            },
            {
                "label": "164 / 14 years",
                "option": "164"
            },
            {
                "label": "176 / 16 years",
                "option": "176"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Size"
        },
        "showConfig": {
            "label": "Size"
        },
        "saveConfig": {
            "isRequired": true,
            "label": "Size"
        }
    },
    {
        "key": "condition",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {
                "label": "New with tags",
                "option": "new_with_tags"
            },
            {
                "label": "Like new",
                "option": "like_new"
            },
            {
                "label": "Great",
                "option": "great"
            },
            {
                "label": "Good",
                "option": "good"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Condition"
        },
        "showConfig": {
            "label": "Condition"
        },
        "saveConfig": {
            "isRequired": true,
            "label": "Condition"
        }
    },
    {
      "key": "country",
      "scope": "public",
      "schemaType": "enum",
      "enumOptions": countryCodes.map(countryCode => ({
        "label": countryCode.en,
        "option": countryCode.en,
      })),
      "filterConfig": {
        "indexForSearch": true,
        "group": "primary",
        "label": "Country",
      },
      "showConfig": {
        "label": "Country",
      },
      "saveConfig": {
        "label": "Country",
      },
    }
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */
const cashBrandTransactionProcess = getBrandProcessCash();
const alias = getDefaultProcessAlias();

export const listingTypes = [
  {
    listingType: 'product',
    label: 'Purchase cash',
    transactionType: {
      process: cashBrandTransactionProcess,
      alias:  `${cashBrandTransactionProcess}/${alias}`,
      unitType: 'item',
    },
    stockType: 'oneItem',
    defaultListingFields: {
      price: true,
    },
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
