import { defaultCurrency } from '../util/currency';

export const CURRENCY_KEY = 'brandbackCurrency';

function exist() {
  return typeof window !== 'undefined' && window.localStorage;
}

function set(locale) {
  if (!exist) {
    return;
  }
  window.localStorage.setItem(CURRENCY_KEY, locale);
  reload();
}

function get() {
  return (exist() && window.localStorage.getItem(CURRENCY_KEY)) || defaultCurrency;
}

function reload() {
  if (typeof document === undefined) {
    return;
  }
  document.location.reload(true);
}

export const currenciesHelper = {
  set,
  get,
  exist,
};
