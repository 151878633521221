/**
 * Retrieves branding data from environment variables with fallback defaults.
 *
 * This function collects various branding-related information from environment
 * variables. If an environment variable is not set,
 * it falls back to a default value for styling variables.
 * This data is added to protected data of the users and transactions.
 *
 * @returns {Object} An object containing the following branding information:
 *   - brandUrl: The URL of the marketplace (required)
 *   - marketplaceNameFull: The full name of the marketplace (required)
 *   - marketplaceLogo: The URL of the marketplace logo (required)
 *   - primaryColor: The primary brand color
 *   - secondaryColor: The secondary brand color
 *   - brandFontFamily: The font family used for branding
 *   - brandNameFull: The full name of the brand
 *   - brandMainStoreUrl: The URL of the brand's main store
 *   - languageStyleAddition: Additional language style information (e.g., ".formal")
 *   - marketplaceLogoPosition: The position of the marketplace logo
 *
 * @example
 * const brandingData = getBrandingData();
 */
export const getBrandingData = () => {
  return {
    brandUrl: process.env.REACT_APP_MARKETPLACE_ROOT_URL,
    marketplaceNameFull: process.env.REACT_APP_MARKETPLACE_NAME_FULL,
    marketplaceLogo: process.env.REACT_APP_MARKETPLACE_LOGO,
    primaryColor: process.env.REACT_APP_BRAND_COLOR_PRIMARY || '#484848',
    secondaryColor: process.env.REACT_APP_BRAND_COLOR_SECONDARY || '#484848',
    brandFontFamily: process.env.REACT_APP_BRAND_FONT_FAMILY || 'Avenir',
    brandNameFull: process.env.REACT_APP_BRAND_NAME_FULL || 'GERRY WEBER',
    brandMainStoreUrl: process.env.REACT_APP_BRAND_MAIN_STORE_URL || 'https://gerryweber.de',
    languageStyleAddition: process.env.REACT_APP_LANGUAGE_STYLE_ADDITION || '',
    marketplaceLogoPosition: process.env.REACT_APP_MARKETPLACE_LOGO_POSITION || 'center',
  };
};
