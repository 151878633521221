const TOTAL_COMMISSION_RATE = parseFloat(process.env.REACT_APP_TOTAL_COMMISSION_RATE);
const RESALE_PRICE_RATE_NEW_WITH_TAGS = parseFloat(
  process.env.REACT_APP_RESALE_PRICE_RATE_NEW_WITH_TAGS
);
const RESALE_PRICE_RATE_LIKE_NEW = parseFloat(process.env.REACT_APP_RESALE_PRICE_RATE_LIKE_NEW);
const RESALE_PRICE_RATE_GREAT = parseFloat(process.env.REACT_APP_RESALE_PRICE_RATE_GREAT);
const RESALE_PRICE_RATE_GOOD = parseFloat(process.env.REACT_APP_RESALE_PRICE_RATE_GOOD);

export default class Pricing {
  getResalePrice(originalPrice, currentPrice, condition) {
    const conditionFactors = {
      new_with_tags: RESALE_PRICE_RATE_NEW_WITH_TAGS,
      like_new: RESALE_PRICE_RATE_LIKE_NEW,
      great: RESALE_PRICE_RATE_GREAT,
      good: RESALE_PRICE_RATE_GOOD,
    };

    if (originalPrice) {
      const resalePriceNotRounded = originalPrice * conditionFactors[condition];
      const resalePrice = parseFloat(resalePriceNotRounded.toFixed(2));

      if (currentPrice) {
        return resalePrice > currentPrice ? currentPrice : resalePrice;
      } else {
        return resalePrice;
      }
    } else {
      return 0.0;
    }
  }

  getCommissionPercentage() {
    const percentage = TOTAL_COMMISSION_RATE;

    if (isNaN(percentage)) {
      throw new Error(`${percentage} is not a number.`);
    }

    return percentage;
  }

  /**
   * Calculates the premium that is often applied for vouchers.
   * This function is specific to each brand's voucher system and should be adjusted according to the brand's policies.
   * Specific to BRAND_NAME!
   * If rounding is enabled via applyRouding, the payout is rounded up to the nearest increment of roundingAmount.
   * If rounding is disabled, the payout with premium is returned without rounding.
   *
   * @param {number} basePayout - The original payout amount before any premiums.
   * @param {number} premiumPercentage - The percentage of the premium to be added to the base payout.
   * @param {number} [roundingAmount=5] - The increment to which the payout will be rounded up. Defaults to 5.
   * @param {boolean} [applyRouding=true] - Controls whether the payout should be rounded to the nearest increment of roundingAmount. Defaults to true.
   * @returns {string} The calculated premium, as a string.
   */
  static calculatePayoutPremium(
    basePayout,
    premiumPercentage,
    applyRouding = true,
    roundingAmount = 5
  ) {
    // First, calculate the total payout including the premium.
    let payoutWithPremium = parseFloat(basePayout) * (1 + premiumPercentage / 100);
    // Apply rounding if needed - this is always applied to the total payoutWithPremium which is used to calculate voucher amount
    if (applyRouding) {
      payoutWithPremium = Math.ceil(payoutWithPremium / roundingAmount) * roundingAmount;
    }
    // Calculate and return just the premium amount by subtracting the basePayout from the total payout.
    let premium = payoutWithPremium - parseFloat(basePayout);
    return premium.toFixed(2); // Ensure the result is a string formatted to two decimal places.
  }

  /**
   * Calculates the payout amount including a premium that is often applied for vouchers.
   * This function is specific to each brand's voucher system and should be adjusted according to the brand's policies.
   * Specific to BRAND_NAME!
   * If rounding is enabled via applyRouding, the payout is rounded up to the nearest increment of roundingAmount.
   * If rounding is disabled, the payout with premium is returned without rounding.
   *
   * @param {number} basePayout - The original payout amount before any premiums.
   * @param {number} premiumPercentage - The percentage of the premium to be added to the base payout.
   * @param {number} [roundingAmount=5] - The increment to which the payout will be rounded up. Defaults to 5.
   * @param {boolean} [applyRouding=true] - Controls whether the payout should be rounded to the nearest increment of roundingAmount. Defaults to true.
   * @returns {string} The calculated payout with premium, rounded up to the nearest 5€ increment, as a string.
   */
  static calculatePayoutWithPremium(
    basePayout,
    premiumPercentage,
    applyRouding = true,
    roundingAmount = 5
  ) {
    const premium = this.calculatePayoutPremium(
      basePayout,
      premiumPercentage,
      applyRouding,
      roundingAmount
    );
    let totalPayout = parseFloat(basePayout) + parseFloat(premium);
    return totalPayout.toFixed(2);
  }
}
