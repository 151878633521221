import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl, isCustomer, currentCurrency, customerTotalConverted, providerTotalConverted } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);
  const customerTotalPrice =
    !isNaN(customerTotalConverted) &&
    formatMoney(intl, new Money(((transaction.attributes.payinTotal.amount !== 0 ? customerTotalConverted : 0) / 100) * 100, currentCurrency));

  const providerTotalPrice =
    !isNaN(providerTotalConverted) &&
    formatMoney(intl, new Money(((transaction.attributes.payoutTotal.amount !== 0 ? providerTotalConverted : 0) / 100) * 100, currentCurrency));

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        {isCustomer && <div className={css.totalPrice}>{customerTotalPrice || formattedTotalPrice}</div>}
        {isProvider && <div className={css.totalPrice}>{providerTotalPrice || formattedTotalPrice}</div>}
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
