import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './FormElement.module.css';

const FormElement = ({ labelId, icon, showIconInfo, setShowIconInfo, iconInfoTextId, intl, children, isComment = false, isSetPrice = false }) => (
  <div className={css.formElement}>
    <div className={css.iconLabelRow}>
      <label>{intl.formatMessage({ id: labelId })}</label>
      {icon && showIconInfo !== undefined && (
        <div
          className={css.infoIcon}
          onMouseEnter={() => setShowIconInfo(true)}
          onMouseLeave={() => setShowIconInfo(false)}
        >
          <FontAwesomeIcon icon={icon} />
          {showIconInfo && (
            <div className={css.iconInfoBox}>
              {intl.formatMessage({ id: iconInfoTextId })}
            </div>
          )}
        </div>
      )}
    </div>
    <div className={isComment ? css.commentContainer : isSetPrice ? css.setPriceContainer : css.inputContainer}>
      <span className={isSetPrice ? css.setPriceInput : css.input}>{children}</span>
    </div>
  </div>
);

export default FormElement;