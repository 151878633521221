import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './CookieBanner.module.css';
import { replaceUnderscoresWithHyphens } from '../../util/string';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsentGiven');
    if (!consentGiven) {
      setIsVisible(true);
      if (typeof _paq !== 'undefined') {
        _paq.push(['trackEvent', 'Cookies', 'CookieBannerView']);
      }
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsentGiven', 'true');
    setIsVisible(false);
    if (typeof _paq !== 'undefined') {
      _paq.push(['rememberCookieConsentGiven']);
      _paq.push(['trackEvent', 'Cookies', 'CookieBannerAccept']);
    }
  };

  const handleRejectAll = () => {
    localStorage.setItem('cookieConsentGiven', 'false');
    setIsVisible(false);
    if (typeof _paq !== 'undefined') {
      _paq.push(['trackEvent', 'Cookies', 'CookieBannerReject']);
    }
  };

  if (!isVisible) {
    return null;
  }

  const brandNameOriginal = process.env.REACT_APP_BRAND_NAME;
  const brandName = replaceUnderscoresWithHyphens(brandNameOriginal);
  const policyUrl = `/p/privacy-policy-${brandName}`;

  return (
    <div className={css.cookieBanner}>
      <div className={css.content}>
        <h2 className={css.title}>
          <FormattedMessage id="CookieBanner.title" />
        </h2>
        <p className={css.description}>
          <FormattedMessage id="CookieBanner.description" />{' '}
          <a href={policyUrl} className={css.link}>
            <FormattedMessage id="CookieBanner.policyLink" />
          </a>
        </p>
      </div>
      <div className={css.buttons}>
        <button onClick={handleRejectAll} className={css.rejectButton}>
          <FormattedMessage id="CookieBanner.rejectButton" />
        </button>
        <button onClick={handleAcceptAll} className={css.acceptButton}>
          <FormattedMessage id="CookieBanner.acceptButton" />
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
