import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { replaceUnderscoresWithHyphens } from '../../util/string';

const brandNameOriginal = process.env.REACT_APP_BRAND_NAME;
const brandName = replaceUnderscoresWithHyphens(brandNameOriginal);

const TOS_ASSET_NAME = `terms-of-service-${brandName}`;
const PRIVACY_POLICY_ASSET_NAME = `privacy-policy-${brandName}`;

export const loadData = (params, search) => dispatch => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};
