const allLocales = [
  { key: 'en', label: 'English' },
  { key: 'fr', label: 'French' },
  { key: 'de', label: 'German' },
  { key: 'it', label: 'Italian' },
  { key: 'es', label: 'Spanish' },
];

const defaultLocale = process.env.REACT_APP_DEFAULT_LOCALE || 'en';
if (!allLocales.some(locale => locale.key === defaultLocale)) {
  throw new Error(`Default locale "${defaultLocale}" is not included in allLocales.`);
}

const configurationLocales = process.env.REACT_APP_AVAILABLE_LOCALES || defaultLocale;

const configurationLocalesParsed = configurationLocales
  .split(',')
  .map(locale => locale.trim())
  .filter(Boolean);

const localeOptions = allLocales.filter(locale => configurationLocalesParsed.includes(locale.key));

if (localeOptions.length === 0) {
  localeOptions.push(allLocales.find(locale => locale.key === defaultLocale));
}

const languageSwitchEnabled = process.env.REACT_APP_LANGUAGE_SWITCH_ENABLED === 'true';

export { defaultLocale, localeOptions, languageSwitchEnabled };
