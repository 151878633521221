import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { manageDisableScrolling } from '../../ducks/ui.duck';

import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { languagesHelper as language } from '../../helpers/languages';
import { currenciesHelper as currency } from '../../helpers/currencies';

import { localeOptions, defaultLocale, languageSwitchEnabled } from '../../intl';
import {
  currencyOptions,
  defaultCurrency,
  currencySwitchEnabled,
  currenciesByLocale,
} from '../../util/currency';

import { Modal, IconSettingsDots } from '../../components';
import SettingsPopupForm from './SettingsPopupForm';

import css from './SettingsPopup.module.css';

const SettingsPopupComponent = props => {
  const { currentUser, onManageDisableScrolling, onUpdateProfile, intl, currentPage } = props;

  if (!languageSwitchEnabled && !currencySwitchEnabled) return null;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSearchPage = currentPage === 'SearchPage';
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();

  let currentLocale = language.get();
  let currentCurrency = currentUser?.attributes?.profile?.publicData?.currency || currency.get();

  const handleSettingsSwitch = async (lang, curr) => {
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        publicData: {
          language: lang,
          currency: curr,
        },
      });
    }

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('brandbackLanguage', lang);
      window.localStorage.setItem('brandbackCurrency', curr);
    }

    if (typeof document === undefined) {
      return;
    }

    if (isSearchPage) {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}));
      document.location.reload(true);
      setIsModalOpen(false);
    } else {
      document.location.reload(true);
    }
  };

  useEffect(() => {
    const storedLocale = localStorage.getItem('brandbackLanguage');
    const storedCurrency = localStorage.getItem('brandbackCurrency');

    if (!storedLocale) {
      // Initialize language from browser language or default locale
      const browserLang = navigator.language.split('-')[0]; // e.g., 'en-US' becomes 'en'
      const locale = localeOptions.find(l => l.key === browserLang)?.key || defaultLocale;

      if (locale !== currentLocale) {
        handleSettingsSwitch(locale, currentCurrency);
      } else {
        if (typeof window !== 'undefined') {
          window.localStorage.setItem('brandbackLanguage', locale);
        }
      }
    }

    if (!storedCurrency) {
      const browserLang = navigator.language.split('-')[0];
      const browserCurrency = currenciesByLocale?.[browserLang];
      const validCurrency =
        currencyOptions.find(c => c.key === browserCurrency)?.key || defaultCurrency;

      const locale = localeOptions.find(l => l.key === browserLang)?.key || defaultLocale;

      if (validCurrency !== currentCurrency) {
        handleSettingsSwitch(locale, validCurrency);
      } else {
        if (typeof window !== 'undefined') {
          window.localStorage.setItem('brandbackCurrency', validCurrency);
        }
      }
    }
  }, [currentLocale, currentCurrency]);

  return (
    <div className={css.root}>
      <div
        className={css.settingsButton}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        {languageSwitchEnabled && (
          <>
            <span className={css.settingsLanguage}>
              {language.labelsTranslator(currentLocale, intl)}
            </span>
          </>
        )}

        {currencySwitchEnabled && (
          <>
            <span className={css.settingsSeparator} />
            <span className={css.settingsCurrency}>
              {currencyOptions?.find(c => c.key === currency.get())?.labelPopup}
            </span>
          </>
        )}
        <IconSettingsDots withMargin={!currencySwitchEnabled} />
      </div>

      <Modal
        id="SettingsPopup"
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        usePortal
        containerClassName={css.modalRoot}
        contentClassName={css.modalContent}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.settingFormWrapper}>
          <SettingsPopupForm
            initialValues={{
              language: currentLocale,
              currency: currency.get(),
            }}
            onSubmit={values => handleSettingsSwitch(values.language, values.currency)}
            localeOptions={localeOptions}
            currencyOptions={currencyOptions}
            onCloseModal={() => {
              setIsModalOpen(false);
            }}
            languageSwitchEnabled={languageSwitchEnabled}
            currencySwitchEnabled={currencySwitchEnabled}
          />
        </div>
      </Modal>
    </div>
  );
};

SettingsPopupComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
};

const { string } = PropTypes;

SettingsPopupComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return { currentUser };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const SettingsPopup = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SettingsPopupComponent);
SettingsPopup.displayName = 'SettingsPopup';

export default SettingsPopup;
