import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Menu, MenuLabel, MenuContent, MenuItem } from '../../components';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { languagesHelper as language } from '../../helpers/languages';
import { localeOptions, defaultLocale, languageSwitchEnabled } from '../../intl';

import css from './MultiLanguageSelect.module.css';

const MultiLanguageSelectComponent = props => {
  const { onUpdateProfile, currentUser, intl } = props;
  let currentLocale = language.get();

  const [isOpen, setIsOpen] = useState(false);

  const onToggleActive = isOpen => {
    setIsOpen(isOpen);
  };

  const handleLanguageSwitch = async lang => {
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        publicData: {
          language: lang,
        },
      });
    }

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('brandbackLanguage', lang);
    }

    if (typeof document === undefined) {
      return;
    }

    document.location.reload(true);

    setIsOpen(false);
  };

  useEffect(() => {
    const storedLocale = localStorage.getItem('brandbackLanguage');

    if (!storedLocale) {
      // Initialize language from browser language or default locale
      const browserLang = navigator.language.split('-')[0]; // e.g., 'en-US' becomes 'en'
      const locale = localeOptions.find(l => l.key === browserLang)?.key || defaultLocale;

      if (locale !== currentLocale) {
        handleLanguageSwitch(locale);
      } else {
        if (typeof window !== 'undefined') {
          window.localStorage.setItem('brandbackLanguage', locale);
        }
      }
    }
  }, [currentLocale]);

  if (!languageSwitchEnabled) {
    return null;
  }

  return (
    <div className={css.langSwitchWrapper}>
      <Menu isOpen={isOpen} onToggleActive={onToggleActive}>
        <MenuLabel className={css.langSwitchLabel} isOpenClassName={css.langSwitchIsOpen}>
          <div className={css.langSwitchIcon} onClick={() => setIsOpen(true)}>
            <span className={classNames(css.flag, css[currentLocale])} />
            {language.labelsTranslator(currentLocale, intl)}
          </div>
        </MenuLabel>
        <MenuContent className={css.langSwitchContent} useDefaultStyle={false}>
          {localeOptions.map(l => {
            return (
              <MenuItem key={l.key}>
                <div
                  className={classNames(css.label, { [css.labelActive]: l.key === currentLocale })}
                  onClick={() => handleLanguageSwitch(l.key)}
                >
                  <span className={classNames(css.flag, css[l.key])} />
                  {language.labelsTranslator(l.key, intl)}
                </div>
              </MenuItem>
            );
          })}
        </MenuContent>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const MultiLanguageSelect = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MultiLanguageSelectComponent);

export default MultiLanguageSelect;
