import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { replaceUnderscoresWithHyphens } from '../../util/string';

const brandNameOriginal = process.env.REACT_APP_BRAND_NAME;
const brandName = replaceUnderscoresWithHyphens(brandNameOriginal);

export const ASSET_NAME = `privacy-policy-${brandName}`;

export const loadData = (params, search) => dispatch => {
  const pageAsset = { privacyPolicy: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
